import React from 'react';

import * as styles from '../../styles/modules/vendors-list.module.scss';

const List = () => {
    return (
        <div className={styles.list}>
            <div className={styles.listGroup}>
                <h2>Cabinets</h2>
                <ul>
                    <li><a href="https://marshfurniture.com/">Marsh Furiture</a></li>
                    <li><a href="https://showplacecabinetry.com/">Showplace Cabinetry</a></li>
                </ul>
            </div>
            <div className={styles.listGroup}>
                <h2>Countertops</h2>
                <ul>
                    <li><a href="https://johnsongraniteinc.com/">Johnson Granite</a></li>
                    <li><a href="https://premierstoneva.com/">Premier Stone Fabrication, Inc.</a></li>
                    <li><a href="https://stonedynamics.com/">Stone Dynamics, Inc.</a></li>
                </ul>
            </div>
            <div className={styles.listGroup}>
                <h2>Decking</h2>
                <ul>
                    <li><a href="https://azek.com/">Azek Building Products</a></li>
                    <li><a href="https://homedepot.com/">Home Depot</a></li>
                    <li><a href="https://lowes.com">Lowes</a></li>
                    <li><a href="https://shopcapps.com/">Capps</a></li>
                    <li><a href="https://smithmountainbuilding.com/">Smith Mountain Building</a></li>
                    <li><a href="https://trex.com/">Trex</a></li>
                </ul>
            </div>
            <div className={styles.listGroup}>
                <h2>Fireplaces</h2>
                <ul>
                    <li><a href="https://dixieproducts.com/">Dixie Products</a></li>
                    <li><a href="https://efireplacestore.com/">eFireplace Store</a></li>
                </ul>
            </div>
            <div className={styles.listGroup}>
                <h2>Lighting</h2>
                <ul>
                    <li><a href="https://build.com/">Build.com</a></li>
                    <li><a href="https://williamslightinggalleries.com/">Williams Lighting Galleries</a></li>
                </ul>
            </div>
            <div className={styles.listGroup}>
                <h2>Paint</h2>
                <ul>
                    <li><a href="https://sherwin-williams.com/">Sherwin-Williams</a></li>
                </ul>
            </div>
            <div className={styles.listGroup}>
                <h2>Plumbing</h2>
                <ul>
                    <li><a href="https://build.com/">Build.com</a></li>
                    <li><a href="https://cmcsupply.com/">CMC Supply, Inc.</a></li>
                    <li><a href="https://ferguson.com/">Ferguson</a></li>
                </ul>
            </div>
            <div className={styles.listGroup}>
                <h2>Siding</h2>
                <ul>
                    <li><a href="https://abcsupply.com/">ABC Supply Co, Inc.</a></li>
                    <li><a href="https://jameshardie.com/">James Hardie</a></li>
                    <li><a href="https://lpcorp.com/smartside/">LP SmartSide</a></li>
                    <li><a href="https://lansingbp.com/">Lansing Building Products</a></li>
                    <li><a href="https://wholesalesidingsupply.com/">Wholesale Siding Supply, Inc.</a></li>
                </ul>
            </div>
            <div className={styles.listGroup}>
                <h2>Stones &amp; Hardscapes</h2>
                <ul>
                    <li><a href="https://bluestoneblock.com/">Blue Stone Block Supermarket, Inc.</a></li>
                    <li><a href="https://boxley.com/">Boxley</a></li>
                    <li><a href="https://dutchqualitystone.com/">Dutch Quality Stone</a></li>
                    <li><a href="https://generalshale.com/">General Shale</a></li>
                </ul>
            </div>
            <div className={styles.listGroup}>
                <h2>Tile</h2>
                <ul>
                    <li><a href="https://morristile.net/">Morris Tile</a></li>
                </ul>
            </div>
            <div className={styles.listGroup}>
                <h2>Windows</h2>
                <ul>
                    <li><a href="https://neathawkwindowsgallery.com/">Neathawk Window &amp; Door Gallery</a></li>
                    <li><a href="https://shopcapps.com/">Capps</a></li>
                    <li><a href="https://smithmountainbuilding.com/">Smith Mountain Building</a></li>
                </ul>
            </div>
        </div>
    );
};

export default List;
