import React from 'react';

// Components
import Layout from '../components/layout';
import Intro from '../components/vendors/intro';
import List from '../components/vendors/list';

import routes from '../constants/routes';

const VendorPage = () => {
    return (
        <Layout slug={routes.vendors} title="Vendors">
            <Intro />
            <List />
        </Layout>
    );
};

export default VendorPage;
