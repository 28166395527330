import React from 'react';

import * as styles from '../../styles/modules/vendors-intro.module.scss';

const Intro = () => {
    return (
        <div className={styles.intro}>
            <div className="copy-width">
                <h1>Our Vendors</h1>
                <p>We are proud to partner with a long list of businesses, both locally and nationally. Here is a list of our most notable vendors and partners.</p>
            </div>
        </div>
    );
};

export default Intro;
